<template>
  <div class="empty">
    <!-- placeholder -->
  </div>
</template>

<script>
import { userGetters } from '@vue-storefront/gemini';

export default {
  name: 'CustomerGtm',
  props: {
    customerData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    isLogged: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isLogged) {
        this.$gtm.push({
          event: 'userData',
          customerLoggedIn: 1,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          firstname: userGetters.getFirstName(this.customerData.user),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          lastname: userGetters.getLastName(this.customerData.user),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          email: userGetters.getEmailAddress(this.customerData.user),
        });
      } else {
        this.$gtm.push({
          event: 'userData',
          customerLoggedIn: 0,
          customerId: 0,
          customerGroupId: '1',
          customerGroupCode: 'GENERAL',
        });
      }
    });
  },
};

</script>

<style scoped>

</style>
