var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-newsletter"},[_c('div',{staticClass:"popup-content"},[_c('div',{staticClass:"subscribe-title"},[_vm._v("\n      "+_vm._s(_vm.$t('Sign up for our newsletter'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"close-icon",on:{"click":_vm.closePopup}},[_c('span',{staticClass:"icon",attrs:{"title":_vm.$t('Close')}},[_c('img',{attrs:{"src":require('../assets/images/cross.svg'),"alt":"close"}})])]),_vm._v(" "),_c('ValidationObserver',{key:"sign-up",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form-newsletter",attrs:{"id":"newsletter-validate-detail"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleSubscribeNewsletter)}}},[_c('div',{staticClass:"container-data"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"nl-col nl-name",attrs:{"id":"newsletter-name","type":"text","name":"firstname","valid":!errors[0],"error-message":_vm.$t(errors[0]),"title":("" + (_vm.$t('Name'))),"placeholder":("" + (_vm.$t('Name'))),"value":_vm.inputName},model:{value:(_vm.inputName),callback:function ($$v) {_vm.inputName=$$v},expression:"inputName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"nl-col nl-last-name",attrs:{"id":"newsletter-surname","type":"text","name":"lastname","title":("" + (_vm.$t('Last name'))),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":("" + (_vm.$t('Last name'))),"value":_vm.inputLastName},model:{value:(_vm.inputLastName),callback:function ($$v) {_vm.inputLastName=$$v},expression:"inputLastName"}})]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"nl-col nl-email",attrs:{"id":"newsletter","type":"email","name":"email","valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":("" + (_vm.$t('Email'))),"title":("" + (_vm.$t('Sign up for our newsletter'))),"value":_vm.emails},model:{value:(_vm.emails),callback:function ($$v) {_vm.emails=$$v},expression:"emails"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"checkbox"},[_c('span',{staticClass:"box-check"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfCheckbox',{attrs:{"name":"privacy-newsletter","label":_vm.$t(
                    // eslint-disable-next-line max-len
                    'I confirm that I am 16 years or older and I consent to Lazzari processing my personal data in order to send personalized marketing material in accordance with privacy policy.'
                  ),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"value":_vm.checked},on:{"click":_vm.handleActive},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})]}}],null,true)})],1)]),_vm._v(" "),_c('label',{staticClass:"submit-text"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'By clicking “subscribe”, I agree that my information can be used for marketing segmentation.'
            ))+"\n        ")]),_vm._v(" "),_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('SfButton',{staticClass:"button btn-subscribe",attrs:{"type":"submit","title":("" + (_vm.$t('Subscribe'))),"disabled":invalid || !_vm.checked},on:{"click":function($event){return _vm.handleSubscribeNewsletter(_vm.emails, _vm.inputName, _vm.inputLastName)}}},[_vm._v("\n            "+_vm._s(_vm.$t('Subscribe'))+"\n          ")])],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }