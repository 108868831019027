<template>
  <div
    id="cart"
    class="cart-mini"
  >
    <!-- title must be not empty to display mini cart header -->
    <SfSidebar
      v-e2e="'sidebar-cart'"
      :visible="isCartSidebarOpen"
      title=" "
      class="sf-sidebar--right"
      @close="toggleCartSidebar"
    >
      <LazyHydrate when-visible>
        <AppHeader class="header-mini-cart" />
      </LazyHydrate>
      <template #circle-icon="{ close }">
        <div
          class="close-icon"
          @click="close"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L17 17"
              stroke="#171717"
              stroke-width="2"
            />
            <path
              d="M17 1L1 17"
              stroke="#171717"
              stroke-width="2"
            />
          </svg>
        </div>
      </template>
      <transition
        name="sf-collapse-top"
        mode="out-in"
      >
        <div class="notifications">
          <SfNotification
            v-if="!isLoaderVisible"
            :visible="visible"
            :title="$t('Are you sure?')"
            :message="
              $t('Are you sure? you want to remove this item from your cart?')
            "
            type="secondary"
          >
            <template #action>
              <div class="button-wrap">
                <SfButton
                  class="sf-button_remove_item"
                  @click="actionRemoveItem(tempProduct)"
                >
                  {{ $t('Si') }}
                </SfButton>
                <SfButton @click="visible = false">
                  {{ $t('Annulla') }}
                </SfButton>
              </div>
            </template>
            <template #close>
              <div />
            </template>
            <template #icon>
              <SfIcon style="display: none" />
            </template>
          </SfNotification>
        </div>
      </transition>
      <SfLoader
        v-if="isLoaderVisible"
        :loading="isLoaderVisible"
      >
        <div />
      </SfLoader>
      <template #content-top>
        <SfProperty
          v-if="totalItems"
          class="sf-property--large cart-summary desktop-only"
          :name="$t('Carrello')"
          :value="totalItems"
        />
      </template>
      <transition
        name="sf-fade"
        mode="out-in"
      >
        <div
          v-if="totalItems && products"
          key="my-cart"
          class="my-cart"
        >
          <div class="collected-product-list">
            <div
              v-for="product in products"
              :key="getCartItemListKey(product)"
              class="cart-item-list"
              @click:remove="sendToRemove({ product })"
            >
              <div class="cart-item-media">
                <figure>
                  <img
                    :src="
                      cartGetters.getItemImage(product)
                        ? cartGetters.getItemImage(product)
                        : ''
                    "
                    alt="Image"
                  >
                </figure>
              </div>
              <div class="cart-item-details">
                <div class="cart-item-content">
                  <div class="cart-item-sku">
                    <p>{{ productSKU(product) }}</p>
                  </div>
                  <nuxt-link
                    :to="localePath(buildProductUrl(product.product))"
                    @click.native="toggleCartSidebar"
                  >
                    <h3 class="cart-item-name">
                      {{ cartGetters.getItemName(product) }}
                    </h3>
                  </nuxt-link>
                  <div
                    v-if="cartGetters.productHasSpecialPrice(product)"
                    class="cart-item-price"
                  >
                    <p class="cart-item-price__special">
                      {{
                        cartGetters.getItemPrice(product).special &&
                          $n(
                            cartGetters.getItemPrice(product).special,
                            'currency'
                          )
                      }}
                    </p>
                    <p class="cart-item-price__old">
                      {{
                        $n(
                          cartGetters.getItemPrice(product).regular,
                          'currency'
                        )
                      }}
                    </p>
                  </div>
                  <div
                    v-else
                    class="cart-item-price"
                  >
                    <p class="cart-item-price__special">
                      {{
                        $n(
                          cartGetters.getItemPrice(product).regular,
                          'currency'
                        )
                      }}
                    </p>
                  </div>
                  <div class="cart-item-configuration">
                    <div v-if="getAttributes(product).length > 0">
                      <SfProperty
                        v-for="(attr, index) in getAttributes(product)"
                        :key="index"
                        :name="$t(attr.option_label)"
                        :value="attr.value_label"
                      />
                    </div>
                  </div>
                </div>
                <div class="cart-item-button">
                  <div class="sf-collected-product__quantity-wrapper">
                    <SfQuantitySelector
                      :disabled="loading"
                      :qty="cartGetters.getItemQty(product)"
                      class="sf-collected-product__quantity-selector"
                      @input="updateCartItemQty(product, $event)"
                    />
                  </div>
                  <div class="cart-item-remove">
                    <a @click="sendToRemove({ product })">
                      <svg
                        class="desktop-only"
                        height="20"
                        width="14"
                      >
                        <rect
                          x="5"
                          y="1"
                          width="5"
                          height="1"
                        />
                        <rect
                          x="1"
                          y="2"
                          width="13"
                          height="3"
                        />
                        <rect
                          x="2"
                          y="6"
                          width="11"
                          height="15"
                        />
                      </svg>
                      <svg
                        class="smartphone-only"
                        height="16"
                        width="11"
                      >
                        <rect
                          x="4"
                          y="1"
                          width="4"
                          height="1"
                        />
                        <rect
                          x="1"
                          y="2"
                          width="10"
                          height="2"
                        />
                        <rect
                          x="2"
                          y="5"
                          width="8"
                          height="12"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          key="empty-cart"
          class="empty-cart"
        >
          <div class="empty-cart__banner">
            <SfHeading
              :title="$t('Your shopping cart is empty')"
              :level="2"
              class="empty-cart__heading"
              :description="
                $t(
                  'It looks like you haven\'t added any items to the bag yet. Start shopping to fill it out.'
                )
              "
            />
          </div>
        </div>
      </transition>
      <template #content-bottom>
        <transition name="sf-fade">
          <div v-if="totalItems">
            <nuxt-link :to="localePath('/checkout/user-account')">
              <SfButton
                v-e2e="'go-to-checkout-btn'"
                class="sf-button--full-width color-secondary"
                @click="toggleCartSidebar"
              >
                {{ $t('Acquista ') }}&nbsp;
                {{
                  totals.subtotal <= totals.special
                    ? $n(totals.subtotal, 'currency')
                    : $n(totals.special, 'currency')
                }}
              </SfButton>
            </nuxt-link>
            <nuxt-link :to="localePath('/')">
              <span
                v-e2e="'go-to-cart-btn'"
                class="button-go-to-home"
                @click="toggleCartSidebar"
              >
                {{ $t('Return to home') }}
              </span>
            </nuxt-link>
          </div>
          <div v-else>
            <SfButton
              class="sf-button--full-width color-primary"
              @click="toggleCartSidebar"
            >
              {{ $t('Torna allo shopping') }}
            </SfButton>
          </div>
        </transition>
      </template>
    </SfSidebar>
  </div>
</template>
<script>
import {
  SfLoader,
  SfNotification,
  SfSidebar,
  SfHeading,
  SfButton,
  SfProperty,
  // SfPrice,
  // SfCollectedProduct,
  // SfImage,
  SfQuantitySelector,
  SfIcon,
  // SfLink,
} from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';
import {
  computed,
  defineComponent,
  ref,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import {
  useCart,
  useUser,
  cartGetters,
  useExternalCheckout,
  productGetters,
} from '@vue-storefront/gemini';
import _debounce from 'lodash.debounce';
import { useUiState, useUiNotification, useUrlHelpers } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useAddToCart } from '~/helpers/cart/addToCart';
import {
  getPriceFixedDecimals,
} from '~/helpers/product/productData';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    LazyHydrate,
    SfLoader,
    SfNotification,
    SfSidebar,
    SfIcon,
    SfButton,
    SfHeading,
    SfProperty,
    // SfPrice,
    // SfCollectedProduct,
    // SfImage,
    SfQuantitySelector,
    // CouponCode,
    AppHeader,
    // SfLink,
  },

  setup() {
    const trans = useI18n();
    const { initializeCheckout } = useExternalCheckout();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const router = useRouter();
    const { app } = useContext();
    const {
      app: {
        $gtm: googleTagManager,
      },
    } = useContext();
    const {
      cart,
      removeItem,
      updateItemQty,
      load: loadCart,
      loading,
      error: cartError,
    } = useCart();
    const { showCartError } = useAddToCart();
    const { isAuthenticated } = useUser();
    const { send: sendNotification, notifications } = useUiNotification();

    const products = computed(() => cartGetters.getItems(cart.value));
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const getAttributes = (product) => product.configurable_options || [];
    const visible = ref(false);
    const isLoaderVisible = ref(false);
    const tempProduct = ref();
    const { buildProductUrl } = useUrlHelpers();

    const productSKU = ({ product }) => product?.value?.merchant_sku;

    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadCart();
    });

    const goToCheckout = async () => {
      const redirectUrl = await initializeCheckout({
        baseUrl: '/checkout/user-account',
      });
      await router.push(`${app.localePath(redirectUrl)}`);
    };

    const sendToRemove = ({ product }) => {
      if (notifications.value.length > 0) {
        notifications.value[0].dismiss();
      }

      visible.value = true;
      tempProduct.value = product;
    };

    const removeCartItemGtm = (product) => {
      let gtmProduct = {};
      gtmProduct = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        name: product?.product?.name,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        product_id: productGetters.getProductMerchantSku(product.product),
        price: getPriceFixedDecimals(product.product.price_range.minimum_price.final_price.value),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        category: product.product.categories[0].name,
        currency: 'EUR',
        discount: getPriceFixedDecimals((product.product.price_range.minimum_price.regular_price.value
          - product.product.price_range.minimum_price.final_price.value)),
        full_price: getPriceFixedDecimals(product.prices.regular_price.value),
      };

      googleTagManager.push({
        event: 'remove_from_cart',
        ecommerce: {
          currencyCode: 'EUR',
          click: {
            products: [gtmProduct],
          },
        },
      });
    };

    const actionRemoveItem = async (product) => {
      isLoaderVisible.value = true;

      await removeItem({ product });

      isLoaderVisible.value = false;
      visible.value = false;
      removeCartItemGtm(product);
      sendNotification({
        id: Symbol('product_removed'),
        message: `${cartGetters.getItemName(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          product,
        )} ${trans.t('successfully removed from your cart')}`,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Product removed',
      });
    };

    const updateCartItemQty = async (product, quantity) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      await updateItemQty({ product, quantity: Number.parseInt(quantity, 10) });

      if (cartError.value.updateItemQty) {
        showCartError(cartError.value.updateItemQty);
      }
    };

    const getCartItemListKey = (cartItem) => {
      if (
        cartItem
        && cartItem?.configurable_options
        && cartItem.configurable_options?.length > 0
      ) {
        const variantKey = cartItem.configurable_options
          .map(
            (option) => `${option.configurable_product_option_uid}-${option.configurable_product_option_value_uid}`,
          )
          .join('-');
        return `${cartItem.product?.merchant_sku}-${variantKey}`;
      }

      return `${cartItem.product?.merchant_sku}`;
    };

    return {
      trans,
      sendToRemove,
      actionRemoveItem,
      loading: computed(() => !!loading.value),
      isAuthenticated,
      products,
      removeItem,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      updateCartItemQty: _debounce(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        (...params) => updateCartItemQty(...params),
        1000,
      ),
      isCartSidebarOpen,
      notifications,
      visible,
      isLoaderVisible,
      tempProduct,
      toggleCartSidebar,
      goToCheckout,
      totals,
      totalItems,
      cartGetters,
      getAttributes,
      sendNotification,
      productSKU,
      buildProductUrl,
      getCartItemListKey,
    };
  },

});
</script>

<style lang="scss" scoped>
#cart {
  --sidebar-z-index: 3;
  --overlay-z-index: 3;
  @include for-desktop {
    & > * {
      --sidebar-bottom-padding: var(--spacer-base);
      --sidebar-content-padding: var(--spacer-base);
    }
  }
}
@include for-mobile {
  .close-icon {
    display: none;
  }
}

.close-icon {
  position: fixed;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.notifications {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  z-index: 99999;
  .sf-notification {
    padding: 20px;
    .button-wrap {
      margin-top: 15px;
      display: flex;
      column-gap: 15px;
      justify-content: center;
    }
  }
}
.cart-summary {
  margin-top: var(--spacer-xl);
}

.my-cart {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__total-items {
    margin: 0;
  }

  &__total-price {
    --price-font-size: var(--font-size--xl);
    --price-font-weight: var(--font-weight--medium);
    margin: 0 0 var(--spacer-base) 0;
  }
}

.empty-cart {
  --heading-description-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-color: var(--c-primary);
  --heading-title-font-weight: var(--font-weight--semibold);
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;

  &__banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  &__heading {
    padding: 0 var(--spacer-base);
  }

  &__image {
    --image-width: 16rem;
    margin: 0 0 var(--spacer-2xl) 7.5rem;
  }

  @include for-desktop {
    --heading-title-font-size: var(--font-size--xl);
    --heading-title-margin: 0 0 var(--spacer-sm) 0;
  }
}

.collected-product-list {
  flex: 1;
}

.collected-product {
  margin: 0 0 var(--spacer-sm) 0;

  &__properties {
    margin: var(--spacer-xs) 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 2;

    &:first-child {
      margin-bottom: 8px;
    }
  }

  &__actions {
    transition: opacity 150ms ease-in-out;
  }

  &__save,
  &__compare {
    --button-padding: 0;

    &:focus {
      --cp-save-opacity: 1;
      --cp-compare-opacity: 1;
    }
  }

  &__save {
    opacity: var(--cp-save-opacity, 0);
  }

  &__compare {
    opacity: var(--cp-compare-opacity, 0);
  }

  &:hover {
    --cp-save-opacity: 1;
    --cp-compare-opacity: 1;
    @include for-desktop {
      .collected-product__properties {
        display: none;
      }
    }
  }
}

.cart-mini {
  .cart-item-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .cart-item-media {
      flex: 32%;
      max-width: 139px;
    }

    .cart-item-details {
      align-content: space-between;
      display: flex;
      flex-wrap: wrap;
      flex: calc(68% - 26px);
      padding-left: 26px;

      > div,
      > h3 {
        flex: 100%;
        width: 100%;
        height: max-content;
      }

      .cart-item-sku p {
        color: #9fa6ad;
        font-family: 'Spartan';
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        text-transform: uppercase;
      }

      .cart-item-name {
        color: #d28571;
        font-family: 'Frank Ruhl Libre';
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 32px;
      }

      .cart-item-price {
        margin-bottom: 15px;

        > p {
          display: inline-block;
          font-family: 'Spartan';
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 20px;
        }

        .cart-item-price__old,
        p:last-child {
          color: #9fa6ad;
          text-decoration-line: line-through;
        }

        .cart-item-price__special,
        p:first-child {
          color: #222a24;
          padding-right: 10px;
          text-decoration-line: none;
        }

        .cart-item-price__special:empty {
          color: red;
          padding-right: 0;
        }
      }

      .cart-item-remove {
        a {
          align-items: center;
          background: #fdcfc1;
          border: 1px solid #fdcfc1;
          cursor: pointer;
          display: flex;
          height: 100%;
          justify-content: center;
          margin-left: -1px;
          width: 100%;

          svg {
            fill: #fff;
          }

          &:hover {
            background: #fff;

            svg {
              fill: #fdcfc1;
            }
          }
        }
      }

      .cart-item-button {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  @media (max-width: 1023px) {
    .cart-item-list {
      .cart-item-details {
        padding-left: 19px;

        .cart-item-sku p {
          color: #5e6770;
          font-size: 8px;
          line-height: 16px;
        }

        .cart-item-name {
          font-size: 16px;
          line-height: 24px;
        }

        .cart-item-price > p {
          font-size: 10px;
        }

        .cart-item-button {
          max-width: 300px;
        }
      }
    }
  }
}
</style>

<style>
.cart-mini {
  position: relative;
  z-index: 100;
}

.cart-mini .sf-header__logo {
  display: flex;
}

.cart-mini .sf-image--wrapper {
  width: 100%;
}

.cart-mini .sf-image--wrapper.empty-cart__image {
  margin: 0;
}

.cart-mini .cart-item-media img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.cart-mini p {
  margin: 0;
}

.cart-mini figure {
  display: flex;
  margin: 0;
  height: 100%;
  width: 100%;
}

.cart-mini .sf-collected-product__actions {
  display: none;
}

.cart-mini .sf-sidebar__bottom {
  padding-left: 65px;
  padding-right: 65px;
}

.cart-mini .sf-sidebar__top {
  padding: 60px 65px 0;
}

.cart-mini .cart-summary {
  border-bottom: 1px solid #c6c8c6;
  padding-bottom: 20px;
  margin: 0 0 24px;
}

.cart-mini .sf-sidebar__content {
  padding: 0 65px;
}

.cart-mini .sf-collected-product {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin-bottom: 24px;
}

.cart-mini .sf-collected-product__quantity-wrapper {
  position: relative;
  padding: 0;
  flex: calc(100% - 65px);
}

.cart-mini .sf-collected-product__quantity-selector {
  border: 1px solid #ddd;
  height: 45px;
  width: 100%;
}

.cart-mini .cart-item-remove {
  flex: 65px;
}

.cart-mini .sf-collected-product__quantity-selector button {
  font-weight: 300;
  flex: 35%;
  padding: 10px 0 0;
  font-size: 18px;
  color: #222a24;
  font-family: 'Spartan';
  line-height: 43px;
}

.cart-mini .sf-collected-product__quantity-selector .sf-input {
  flex: 30%;
}

.cart-mini .sf-icon {
  margin: auto;
}

.cart-mini .sf-input {
  width: 45px;
  --input-height: 45px;
  padding: 0 5px;
}

.cart-mini .sf-sidebar__top .sf-property__name:after {
  content: '';
}

.cart-mini .sf-sidebar__top .sf-property__value:before {
  content: '(';
}

.cart-mini .sf-sidebar__top .sf-property__value:after {
  content: ')';
}

.cart-mini .sf-sidebar__top .sf-property__name,
.cart-mini .sf-sidebar__top .sf-property__value {
  color: #222a24;
  font-family: 'Spartan';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 23px;
}

.cart-mini .sf-sidebar__bottom .sf-button.color-secondary,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary {
  background: #212a24;
  border: 1px solid #707070;
  color: white;
  font-family: 'Spartan';
  font-size: 14px;
  font-weight: 700;
  height: 65px;
  letter-spacing: 1px;
  line-height: 16px;
  padding: 0;
  text-transform: capitalize;
}

.cart-mini ~ .notifications .sf-notification__message {
  color: #fff;
  padding: 0 30px 0 10px;
}

.cart-mini .notifications .sf-button {
  padding: 0 30px;
  height: 50px;
  line-height: 50px;
}

.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:hover,
.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:active,
.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:focus,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:hover,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:active,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:focus,
.cart-mini .notifications .sf-button:hover,
.cart-mini .notifications .sf-button:active,
.cart-mini .notifications .sf-button:focus {
  background-color: rgba(34, 42, 36, 0.7);
  color: #fff;
  text-decoration-line: none;
}

.cart-mini .cart-item-list .sf-property {
  margin-bottom: 5px;
  padding-right: 15px;
  display: inline-block;
}

.cart-mini .cart-item-list .sf-property:last-child {
  margin-bottom: 5px;
}

.cart-mini .cart-item-list .sf-property__name,
.cart-mini .cart-item-list .sf-property__value {
  color: #222a24;
  font-family: 'Spartan';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
}

.cart-mini .cart-item-list .sf-property__name {
  margin-right: -2px;
}

.cart-mini .sf-input input {
  color: #222a24;
  font-family: 'Spartan';
  font-size: 16px;
  font-weight: 500;
  padding: 5px 0 0;
}

.cart-mini .sf-input__error-message {
  display: block;
  left: -72px;
  min-width: 210px;
  position: absolute;
  width: 100%;
}

.cart-mini .sf-input input:focus-visible {
  outline: 0;
  border: 0;
}

.cart-mini .empty-cart__heading .sf-heading__title.h2 {
  color: #d28571;
  font-family: 'Frank Ruhl Libre';
  font-size: 30px;
  text-align: center;
}

.cart-mini .empty-cart__heading .sf-heading__description {
  font-family: 'Spartan';
}

.cart-mini .notifications .sf-icon {
  margin-right: 20px;
}

.cart-mini .notifications .sf-icon svg {
  --icon-color: #212a24;
}

.cart-mini .sf-notification.color-secondary {
  background: #fdcfc1;
}

.cart-mini .sf-notification__message,
.cart-mini .sf-notification__title {
  color: #212a24;
}

.cart-mini .sf-sidebar__aside {
  height: 100%;
}

.sf-badge--number {
  background-color: #fdcfc1;
  height: 17px;
  width: 17px;
  font-size: 9px;
  color: #222a24;
  font-weight: 700;
  line-height: 19px;
  font-family: 'Spartan';
  position: absolute;
  right: -8px;
  top: -5px;
}

.cart-mini .button-go-to-home {
  margin-top: 20px;
  display: block;
  text-align: center;
  margin-bottom: 15px;
  text-decoration: underline;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

@media (min-width: 1024px) {
  .cart-mini .sf-sidebar__aside {
    width: 570px;
  }

  .cart-mini .sf-collected-product {
    max-width: 100%;
  }

  .cart-mini .sf-collected-product__configuration {
    justify-content: flex-start;
    padding-bottom: 50px;
  }

  .cart-mini .close-icon {
    right: 65px;
    top: 60px;
  }

  .cart-mini .header-mini-cart {
    display: none;
  }

  .cart-mini .sf-sidebar__bottom .sf-button.color-primary {
    max-width: 439px;
  }
}

@media (max-width: 1023px) {
  .cart-mini .sf-sidebar__content,
  .cart-mini .sf-sidebar__top,
  .cart-mini .sf-sidebar__bottom {
    padding-left: 23px;
    padding-right: 23px;
  }

  .cart-mini .cart-item-content div {
    height: max-content;
    line-height: 0;
  }

  .cart-mini .cart-item-list .sf-property__value {
    font-weight: 500;
  }

  .cart-mini .sf-collected-product__quantity-selector {
    height: 40px;
  }

  .cart-mini .sf-collected-product__quantity-selector button {
    font-size: 20px;
    flex: 30%;
    padding: 6px 0 0;
  }

  .cart-mini .sf-collected-product__quantity-selector .sf-input {
    flex: 40%;
  }

  .cart-mini .sf-sidebar__top .cart-summary.desktop-only {
    display: block !important;
  }

  .cart-mini .cart-summary {
    border-bottom: 0;
    margin: 0;
    padding-bottom: 30px;
  }

  .cart-mini .sf-sidebar__top {
    padding-bottom: 0;
    padding-top: 25px;
  }

  .cart-mini .close-icon {
    display: block;
    top: 15px;
    right: 20px;
  }

  .cart-mini .sf-bar__icon {
    display: none;
  }

  .cart-mini .sf-bar {
    background: #fff;
    border-bottom: 1px solid #e9ecf1;
    height: 54px;
  }

  .cart-mini .cart-item-list .sf-property {
    padding-right: 0;
    width: 100%;
  }

  .cart-mini .cart-item-list .sf-property > span {
    font-size: 10px;
    line-height: 14px;
  }

  .cart-mini .sf-collected-product__quantity-wrapper {
    flex: calc(100% - 45px);
  }

  .cart-mini .cart-item-remove {
    flex: 45px;
  }

  .cart-mini .sf-input input {
    font-size: 14px;
    height: 45px;
    line-height: 45px;
  }

  .cart-mini .sf-sidebar__bottom .sf-button.color-secondary,
  .cart-mini .sf-sidebar__bottom .sf-button.color-primary {
    font-size: 14px;
    letter-spacing: 0px;
    height: 55px;
    line-height: 16px;
    font-weight: 700;
  }

  .cart-mini .notifications {
    left: 50%;
    margin: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .cart-mini .sf-header__item_right {
    display: none;
  }

  .cart-mini .header-mini-cart {
    left: 0;
    position: absolute;
    top: -14px;
  }

  .cart-mini .header-mini-cart .sf-header-custom_container {
    position: absolute;
    top: -47px;
  }

  .cart-mini #overlay_menu,
  .cart-mini #mega_menu {
    top: 0;
  }
}

@media (max-width: 767px) {
  .cart-mini .header-mini-cart {
    top: -13px;
  }

  .cart-mini .my-cart .cart-item-list {
    flex-wrap: nowrap;
  }

  .cart-mini .cart-item-list .cart-item-media {
    min-width: 139px;
  }

  .cart-mini .sf-sidebar__top .sf-property__name,
  .cart-mini .sf-sidebar__top .sf-property__value {
    color: #5e6770;
    font-weight: 500;
  }
}
</style>
