<template>
  <div class="popup-newsletter">
    <div class="popup-content">
      <div class="subscribe-title">
        {{ $t('Sign up for our newsletter') }}
      </div>
      <div
        class="close-icon"
        @click="closePopup"
      >
        <span
          class="icon"
          :title="$t('Close')"
        >
          <img
            :src="require('../assets/images/cross.svg')"
            alt="close"
          >
        </span>
      </div>
      <ValidationObserver
        v-slot="{ handleSubmit, invalid }"
        key="sign-up"
      >
        <form
          id="newsletter-validate-detail"
          class="form-newsletter"
          @submit.prevent="handleSubmit(handleSubscribeNewsletter)"
        >
          <div class="container-data">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <SfInput
                id="newsletter-name"
                v-model="inputName"
                type="text"
                name="firstname"
                :valid="!errors[0]"
                :error-message="$t(errors[0])"
                :title="`${$t('Name')}`"
                :placeholder="`${$t('Name')}`"
                class="nl-col nl-name"
                :value="inputName"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <SfInput
                id="newsletter-surname"
                v-model="inputLastName"
                type="text"
                name="lastname"
                class="nl-col nl-last-name"
                :title="`${$t('Last name')}`"
                :valid="!errors[0]"
                :error-message="$t(errors[0])"
                :placeholder="`${$t('Last name')}`"
                :value="inputLastName"
              />
            </ValidationProvider>
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|email"
          >
            <SfInput
              id="newsletter"
              v-model="emails"
              type="email"
              name="email"
              class="nl-col nl-email"
              :valid="!errors[0]"
              :error-message="$t(errors[0])"
              :placeholder="`${$t('Email')}`"
              :title="`${$t('Sign up for our newsletter')}`"
              :value="emails"
            />
          </ValidationProvider>
          <div class="checkbox">
            <span class="box-check">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
              >
                <SfCheckbox
                  v-model="checked"
                  name="privacy-newsletter"
                  :label="
                    $t(
                      // eslint-disable-next-line max-len
                      'I confirm that I am 16 years or older and I consent to Lazzari processing my personal data in order to send personalized marketing material in accordance with privacy policy.'
                    )
                  "
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  :value="checked"
                  @click="handleActive"
                />
              </ValidationProvider>
            </span>
          </div>
          <label class="submit-text">
            {{
              $t(
                'By clicking “subscribe”, I agree that my information can be used for marketing segmentation.'
              )
            }}
          </label>
          <SfLoader
            :class="{ loader: loading }"
            :loading="loading"
          >
            <SfButton
              type="submit"
              class="button btn-subscribe"
              :title="`${$t('Subscribe')}`"
              :disabled="invalid || !checked"
              @click="handleSubscribeNewsletter(emails, inputName, inputLastName)"
            >
              {{ $t('Subscribe') }}
            </SfButton>
          </SfLoader>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import {
  SfInput, SfButton, SfCheckbox, SfLoader,
} from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { useNewsletter, useUser } from '@vue-storefront/gemini';
import { useKlaviyo } from '~/composables';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'NewsletterForm',
  components: {
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { updateSubscription, loading, error } = useNewsletter();
    const { klaviyoCreateClientSubscription } = useKlaviyo();
    const { isAuthenticated } = useUser();

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleSubscribeNewsletter = async (email, name, lastName) => {
      if (email && name && lastName) {
        await updateSubscription({ email: email.toLowerCase(), name, lastName });

        await klaviyoCreateClientSubscription(
          email.toLowerCase(),
          name,
          lastName,
          isAuthenticated.value,
          'footer_form',
        );
        const errorMessage = error.value.updateSubscription;
        emit('closePopup');
        emit('showToast', errorMessage);
      }
    };

    return {
      handleSubscribeNewsletter,
      updateSubscription,
      loading,
    };
  },
  data() {
    return {
      checked: false,
      inputName: this.name,
      inputLastName: this.lastName,
      emails: this.email,
    };
  },
  methods: {
    handleActive() {
      this.checked = !this.checked;
    },
    closePopup() {
      this.$emit('closePopup');
    },
  },
});
</script>

<style lang="scss">
$font-spartan: 'Spartan';

.form-newsletter {
  font-family: $font-spartan;
}

.close-icon {
  font-size: 23px;
  color: #fff;
  opacity: 0.7;
  position: absolute;
  right: 50px;
  top: 50px;

  .icon {
    cursor: pointer;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid #141e16;
    display: block;
    height: 24px;
    width: 24px;

    img {
      width: 24px;
    }
  }
}

.popup-newsletter {
  background-color: #fdcfc1;
  min-height: 400px;
  left: 0;
  position: absolute;
  text-align: center;
  top: -434px;
  width: 100%;
  z-index: 99;
  @include for-mobile {
    top: -481px;
  }

  .popup-content {
    margin: 50px auto;
    max-width: 500px;
    width: 90%;

    .subscribe-title {
      color: #141e16;
      font-size: 30px;
      font-weight: 700;
      line-height: 42.85px;
    }
  }
}

.nl-col {
  background: transparent;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  box-shadow: none;
  color: #141e16;
  display: inline-block;
  font-size: 16px;
  height: 44px;
  line-height: 22.85px;
  padding: 10px;
  text-align: left;
  width: 49%;

  &.invalid {
    margin-bottom: 10px;
  }

  .sf-input__error-message {
    margin-top: 15px;
  }

  input {
    border: none;
    outline: none;
    color: #141e16;

    &::placeholder {
      color: #141e16;
    }
  }

  .sf-input__wrapper {
    margin: 0;
    font-family: $font-spartan;
    background: transparent;

    input {
      font-family: inherit;
      font-size: 16px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #fdcfc1 inset !important;
    }
  }

  .sf-input__bar {
    display: none;
  }
}

.nl-email {
  width: 100%;
}

.container-data {
  position: relative;

  .nl-name {
    float: left;
  }
}

.checkbox {
  clear: left;
  display: block;
  float: left;
  margin: 10px 0;
  position: relative;

  .sf-checkbox__container {
    color: #141e16;
    display: flex;
    text-align: left;
    align-items: flex-start;
    cursor: pointer;

    .sf-checkbox__checkmark {
      width: 16px;
      height: 16px;
      background: #fff;
      margin-top: 3px;
      border-color: #141e16;
    }

    .sf-checkbox__checkmark,
    .sf-checkbox__checkmark:focus,
    .sf-checkbox__checkmark:focus-within,
    .sf-checkbox__checkmark:focus-visible,
    .sf-checkbox__input,
    .sf-checkbox__input:focus,
    .sf-checkbox__input:focus-within,
    .sf-checkbox__input:focus-visible {
      border: none;
      outline: none;
    }

    .sf-icon.color-white {
      --icon-color: #141e16;
    }

    .sf-icon,
    .sf-icon:focus {
      border: none;
      outline: none;
    }
  }
}

.submit-text {
  color: #141e16;
  display: inline-block;
  font-size: 14px;
  padding-left: 30px;
  text-align: left;
  font-weight: 600;
}

.box-check {
  .sf-checkbox__label {
    color: #141e16;
    font-family: $font-spartan;
    font-size: 14px;
    margin-left: 15px;
    cursor: pointer;
    font-weight: 600;
  }
}

.btn-subscribe {
  background: #fff;
  box-shadow: inset 0 0 1px #fff;
  color: #141e16;
  cursor: pointer;
  display: inline-block;
  font-family: $font-spartan;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 12px;
  margin-top: 20px;
  padding: 14px 16px 12px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 300ms;

  &.is-disabled--button {
    background: #f1f2f3;
    color: #e0e0e1;
  }

  &:hover {
    background: #141e16;
    color: #fff;
  }

  &:active {
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .nl-col {
    width: 100%;
  }
  .popup-newsletter {
    top: -527px;
  }

  .close-icon {
    top: 20px;
    right: 15px;
  }

  .popup-newsletter .popup-content .subscribe-title {
    font-size: 20px;
  }
}

@media (max-width: 516px) {
  .popup-newsletter {
    top: -553px;
  }
}

@media (max-width: 399px) {
  .popup-newsletter {
    top: -590px;
  }
}
</style>
