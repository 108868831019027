<template>
  <div id="footer-fluid-checkout">
    <div class="box-footer">
      <div class="box__link-item contatti">
        <div class="item-content">
          <div class="item-title">
            {{ $t('Contacts Us') }}
          </div>
          <div class="link-wrap">
            <a
              :href="'mailto:' + contatti[0]"
              class="link-item"
              target="_blank"
            >
              {{ contatti[0] }}
            </a>
          </div>
          <div class="link-wrap">
            <a
              :href="'tel:' + contatti[1]"
              class="link-item"
              target="_blank"
            >{{
              contatti[1]
            }}</a>
          </div>
        </div>
      </div>
      <div
        v-if="footerBottomLink.links"
        class="copyright"
      >
        <div class="copyright-text">
          {{ $t("© Lazzari. All right reserved.") }}
          <br class="smartphone-only">
          {{ $t("VAT. IT02192410237") }}
        </div>
        <div
          v-for="(service, indexFooterBottomLink) in footerBottomLink.links"
          :key="'indexFooterBottomLink-' + indexFooterBottomLink"
          class="service-footer"
        >
          <nuxt-link
            :to="service.url"
            :target="service.target"
          >
            {{ service.label }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { contentGetters, useContent } from '@vue-storefront/gemini';
import { useFetch, ref } from '@nuxtjs/composition-api';
import { useI18n } from '../helpers/hooks/usei18n';

export default {
  setup() {
    const translate = useI18n();
    const { locale } = translate;

    const identifierFooterBottom = `footer-bottom-links-${locale}`;
    const { blocks, loadBlocks } = useContent(`cmsBlockFooter-${locale}`);

    const footerBottomLink = ref({
      sections: [],
    });

    useFetch(async () => {
      await loadBlocks({
        identifiers: [identifierFooterBottom],
      });
      const blocksContent = contentGetters.getCmsBlockContent(blocks.value);

      footerBottomLink.value = blocksContent[identifierFooterBottom]
        ? blocksContent[identifierFooterBottom]
        : {
          sections: [],
        };
    });

    return {
      identifierFooterBottom,
      footerBottomLink,
      contatti: ['info@lazzarionline.com', '+39 045 7614582'],
    };
  },
};
</script>

<style lang="scss">
$color-text-primary: #0b0e0c;
$font-spartan: 'Spartan';
#footer-fluid-checkout {
  width: 100vw;
  background-color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  .box-footer {
    width: 100%;
    max-width: 1414px;
    margin: 0 auto;
    .box__link-item.contatti {
      display: flex;
      gap: 2rem;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.75rem;
      .item-content {
        .item-title {
          text-transform: uppercase;
          font-family: $font-spartan;
          font-weight: var(--font-weight--bold);
          font-size: var(--font-size--base);
        }
        display: flex;
        gap: 1rem;
        align-items: center;
        font-family: $font-spartan;
        font-size: var(--font-size--sm);
        font-weight: var(--font-weight--medium);
      }
    }
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 0.4375rem;
    text-align: center;
    .copyright-text {
      font-family: $font-spartan;
      font-size: var(--font-size--xs);
      font-weight: var(--font-weight--medium);
      margin: 0;
      @media screen and (max-width: 1023px) {
        flex: 50%;
      }
    }
    .service-footer {
      margin: 0;
      font-family: $font-spartan;
      font-size: var(--font-size--xs);
      font-weight: var(--font-weight--medium);
      @media screen and (max-width: 1023px) {
        flex: 50%;
      }
    }
  }
}

@media screen and (max-width: 513px) {
  #footer-fluid-checkout {
    .box-footer {
      padding: 10px;
      .box__link-item.contatti {
        .item-content {
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }
  }
}
</style>
