<template>
  <div class="empty">
    <!-- placeholder -->
  </div>
</template>

<script>

import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SearchResultsGtm',
  props: {
    hasResult: {
      type: Boolean,
      required: true,
      default: false,
    },
    searchTerm: {
      type: String,
      required: true,
      default: '',
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$gtm.push({
        event: 'view_search_results',
        search_term: this.searchTerm,
        search_result: this.hasResult ? 'yes' : 'no',
      });
    });
  },

});
</script>

<style scoped>

</style>
