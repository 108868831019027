<template>
  <div class="container locales_custom">
    <SfList v-if="availableLocales.length > 0">
      <SfListItem
        v-for="lang in availableLocales"
        :key="lang.code"
      >
        <a :href="'/'+ lang.code">
          <SfCharacteristic class="language">
            <template #title>
              <span
                :class="{ 'active': lang.code !== localeSelect[0].code }"
              >{{ lang.code }}</span>
            </template>
          </SfCharacteristic>
        </a>
      </SfListItem>
    </SfList>
  </div>
</template>

<script>
import { SfList, SfCharacteristic } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';

export default defineComponent({
  name: 'LocaleSelector',
  components: { SfList, SfCharacteristic },
  setup() {
    const { locales, locale } = useI18n();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const localeSelect = computed(() => [...locales].filter((i) => (Object.keys(i).length > 0 && typeof i === 'object'
      ? i.code !== locale
      : i !== locale)));
    const availableLocales = [...locales];

    return {
      availableLocales,
      locale,
      localeSelect,
    };
  },
});
</script>

<style lang='scss'>

.locales_custom {
  span.active {
    font-weight: bold !important;
  }

  span {
    display: block;
    text-transform: uppercase;
  }
}

.container {
  .sf-characteristic__icon {
    display: none;
  }
  .sf-list {
    margin: 0 23px 0 0;
    display: flex;
    list-style: none;
    padding: 0;
    .sf-list__item {
      padding: 0 10px;
      font-size: 14px;
      font-family: var(--font-family--secondary);
      font-weight: var(--font-weight--norm);
      color: #222a24;
      border-left: 1px solid #000;

      &+.sf-list__item {
        border-left: 0;
      }

      a {
        color: #222a24;
        font-family: var(--font-family--secondary);
        font-weight: var(--font-weight--norm);
      }
    }
  }

  .sf-characteristic__description {
    display: none;
  }
}
</style>
<style lang='scss' scoped>
.container {
  margin: 0 -5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;

  .sf-bottom-modal {
    z-index: 2;
    left: 0;
    @include for-desktop {
      --bottom-modal-height: 100vh;
    }
  }

  .sf-bottom-modal::v-deep .sf-bottom-modal__close {
    position: var(--circle-icon-position, absolute);
    top: var(--spacer-xs);
    right: var(--spacer-xs);
  }

  .sf-list {
    .language {
      padding: 0;

      &__flag {
        margin-right: var(--spacer-sm);
      }
    }

    @include for-desktop {
      display: flex;
    }
  }

  &__store {
    &--selected {
      font-weight: bold;
    }
  }

  &__lang {
    width: 20px;
    --button-box-shadow: none;
    background: none;
    padding: 0 5px;
    display: flex;
    align-items: center;
    opacity: 0.5;
    border: none;

    &:hover,
    &--selected {
      opacity: 1;
    }

    &--title {
      --heading-title-font-weight: var(--font-weight--normal);
      padding: var(
        --bottom-modal-title-padding,
        var(--spacer-sm) var(--spacer-lg)
      );
      color: var(--bottom-modal-title-color, var(--c-text));
      text-align: var(--bottom-modal-title-text-align, center);
      @include for-mobile {
        --heading-title-font-size: var(--font-size--xs);
        --heading-title-font-weight: var(--font-weight--bold);
      }
    }
  }
}
</style>
